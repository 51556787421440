import React, { useState, Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FixedObject } from "gatsby-image"
import Carousel from "nuka-carousel"
import classnames from "classnames"
import {
  Maybe,
  IndexQuery,
  GatsbyImageSharpFixed_TracedSvgFragment,
} from "../generated/graphql"
import idx from "idx"

type galleryItem = {
  frontmatter?: Maybe<{
    image?: Maybe<{
      large?: Maybe<{ fixed?: Maybe<GatsbyImageSharpFixed_TracedSvgFragment> }>

      medium?: Maybe<{ fixed?: Maybe<GatsbyImageSharpFixed_TracedSvgFragment> }>

      small?: Maybe<{ fixed?: Maybe<GatsbyImageSharpFixed_TracedSvgFragment> }>
      preview?: Maybe<{
        fixed?: Maybe<GatsbyImageSharpFixed_TracedSvgFragment>
      }>
    }>
  }>
}

const ImageCarousel = ({
  galleryItems,
  carouselIndex,
  width,
  setState,
  className,
  imageSize,
}: {
  galleryItems: Array<galleryItem>
  carouselIndex: number
  className: string
  setState: ({ carouselIndex }: { carouselIndex: number }) => void
  width: number
  imageSize: "large" | "medium" | "small" | "preview"
}) => {
  const height = (width / 3) * 2

  return (
    <div className={classnames("m-auto", className)}>
      <Carousel
        className="m-auto"
        slideIndex={carouselIndex}
        afterSlide={(slideIndex) => setState({ carouselIndex: slideIndex })}
        autoplay={true}
        wrapAround={true}
        slideWidth={`${width}px`}
        width={`${width}px`}
        withoutControls={true}
        heightMode="max"
        initialSlideHeight={height}
      >
        {galleryItems.map((item, i: number) => {
          const image: FixedObject = idx(
            item,
            (_) => _.frontmatter.image[imageSize].fixed
          ) as FixedObject
          if (!image) return <div />
          return <Img key={i} fixed={image} />
        })}
      </Carousel>
    </div>
  )
}

const Home = () => {
  const [{ carouselIndex }, setState] = useState({
    carouselIndex: 0,
  })

  const data: IndexQuery = useStaticQuery(graphql`
    query Index {
      customPagesYaml(slug: { eq: "home" }) {
        internal {
          type
        }
        body
        galleryItems {
          frontmatter {
            image {
              large: childImageSharp {
                fixed(width: 600, height: 400, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
              medium: childImageSharp {
                fixed(width: 400, height: 266, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
              small: childImageSharp {
                fixed(width: 285, height: 190, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
              preview: childImageSharp {
                fixed(width: 100, height: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
          }
        }
        fields {
          markdownBody {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  const galleryItems = idx(data, (_) => _.customPagesYaml.galleryItems)
  const html = idx(
    data,
    (_) => _.customPagesYaml.fields.markdownBody.childMarkdownRemark.html
  )

  if (!(galleryItems && html)) return <div />

  return (
    <Fragment>
      <ImageCarousel
        galleryItems={galleryItems}
        carouselIndex={carouselIndex}
        width={285}
        setState={setState}
        className="block md:hidden"
        imageSize="small"
      />
      <ImageCarousel
        galleryItems={galleryItems}
        carouselIndex={carouselIndex}
        width={400}
        setState={setState}
        className="hidden md:block lg:hidden"
        imageSize="medium"
      />
      <ImageCarousel
        galleryItems={galleryItems}
        carouselIndex={carouselIndex}
        width={600}
        setState={setState}
        className="hidden lg:block"
        imageSize="large"
      />
      <div className="flex justify-around w-1/2 m-auto my-2 items-center">
        {galleryItems.map((item, i: number) => {
          const image = idx(
            item,

            (_) => _.frontmatter.image.preview.fixed
          )
          if (!image) return <div />
          const className = classnames(
            "transition",
            "border",
            "border-solid",
            i == carouselIndex
              ? "border-gray-800"
              : "border-gray-400 opacity-75"
          )
          return (
            <div
              key={i}
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault
                setState({ carouselIndex: i })
              }}
            >
              <Img fixed={image} className={className} />
            </div>
          )
        })}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Fragment>
  )
}

export default Home
